<template>
  <ContentCard class="navigation" :title="$data.title">
    <!-- Zurück Button -->
    <SidebarNavigationBackButton
      v-if="activeNavigation !== null"
      @click="reset"
    />

    <!-- Einträge ausgeben -->
    <template
      v-for="(link, key) in getNavigation"
      :key="'navigation-sidebar-link-' + key"
    >
      <template v-if="link.divider">
        <!-- Trennlinie -->
        <SidebarNavigationDivider :title="link.title" />
      </template>
      <template v-else>
        <!-- Link -->
        <SidebarNavigationLink
          :link="link"
          :click-function="change"
          :show-arrow="activeNavigation === null"
        />
      </template>
    </template>
  </ContentCard>
</template>

<script>
import ContentCard from "@/components/Wrapper/ContentCard";
import SidebarNavigationLink from "@/components/Links/SidebarNavigationLink";
import SidebarNavigationBackButton from "@/components/Links/SidebarNavigationBackButton";
import SidebarNavigationDivider from "@/components/Divider/SidebarNavigationDivider";

export default {
  name: "SidebarNavigation",
  components: {
    SidebarNavigationDivider,
    SidebarNavigationBackButton,
    SidebarNavigationLink,
    ContentCard,
  },
  props: {
    data: Object,
    makeActive: Object,
  },
  watch: {
    $route() {
      const name = this.$route.name;

      if (name !== "News") {
        for (let m = 0; m < this.getNavigation.length; m++) {
          let menu = this.getNavigation[m];

          if (menu.childs) {
            for (let c = 0; c < menu.childs.length; c++) {
              let child = menu.childs[c];

              if (child.to && child.to.name === name) {
                this.change(menu);
              } else {
                if (child.active && child.active.includes(name)) {
                  this.change(menu);
                }
              }
            }
          }
        }
      }
    },
  },
  computed: {
    getNavigation() {
      if (this.activeNavigation === null) {
        // Kein Aktives Menü
        // Haupteinträge verwenden
        return this.$props.data;
      }
      // Aktives Menü
      return this.activeNavigation;
    },
  },
  data() {
    return {
      // Titel
      title: "Navigation",
      // Fallback Titel
      defaultTitle: "Navigation",
      // Aktive Navigation
      activeNavigation: null,
    };
  },
  methods: {
    /**
     * Aktives Menü wechseln.
     * @param link
     */
    change(link) {
      this.title = link.title;
      this.activeNavigation = link.childs;
    },
    /**
     * Aktives Menü zurücksetzen.
     */
    reset() {
      this.title = this.defaultTitle;
      this.activeNavigation = null;
    },
  },
};
</script>

<style>
.navigation .link:last-child {
  @apply lg:rounded-b-xl;
}
</style>
