<template>
  <!-- Link -->
  <router-link
    v-if="link.to"
    class="link"
    v-bind:class="{ 'router-link-exact-active': activeRoute }"
    :to="link.to"
  >
    <div class="flex items-center space-x-4">
      <span v-if="link.icon" class="material-icons">
        {{ link.icon }}
      </span>
      <span>{{ link.title }}</span>
    </div>
  </router-link>
  <!-- Menü Item -->
  <div class="link group" @click.prevent="clickFunction(link)" v-else>
    <div class="flex items-center space-x-4">
      <span class="material-icons" v-if="link.icon">
        {{ link.icon }}
      </span>
      <!-- Title -->
      <span>{{ link.title }}</span>
    </div>
    <!-- Icon (Arrow Right) -->
    <span class="material-icons-outlined link-icon" v-if="showArrow">
      keyboard_arrow_right
    </span>
  </div>
</template>

<script>
export default {
  name: "SidebarNavigationLink",
  emits: ["click"],
  props: {
    link: Object,
    showArrow: {
      default: true,
      type: Boolean,
    },
    clickFunction: Function,
  },
  computed: {
    activeRoute() {
      if (!this.link.active) {
        return false;
      }
      return this.link.active.includes(this.$route.name);
    },
  },
};
</script>

<style scoped>
.link {
  @apply border-l-4 border-transparent block text-gray-600 hover:text-gray-900 px-5 py-3 hover:bg-gray-50 transition-all ease-linear duration-150 flex justify-between items-center cursor-pointer;
}
.link-icon {
  @apply opacity-40 group-hover:opacity-100;
}

.router-link-exact-active {
  @apply bg-stone-100 border-green-800 text-green-900 !important;
}
</style>
