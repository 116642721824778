<template>
  <metainfo>
    <template v-slot:title="{ content }">{{
      content
        ? `${content} | BSV-Harsewinkel von 1845 e.V.`
        : `BSV-Harsewinkel von 1845 e.V.`
    }}</template>
  </metainfo>
  <!-- App Header -->
  <AppHeader />
  <!-- Scroll to Top Button -->
  <ScrollToTopButton @click="toTop" v-show="scY > 600" />
  <!-- Main -->
  <MainContainer>
    <!-- Sidebar -->
    <template #sidebar>
      <AppSidebar />
    </template>
    <!-- Content -->
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </MainContainer>
</template>

<script>
import AppHeader from "@/partials/AppHeader";
import MainContainer from "@/partials/MainContainer";
import AppSidebar from "@/partials/AppSidebar";
import ScrollToTopButton from "@/components/Buttons/ScrollToTopButton";

export default {
  name: "App",
  components: {
    ScrollToTopButton,
    AppSidebar,
    MainContainer,
    AppHeader,
  },
  data() {
    return {
      scTimer: 0,
      scY: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll: function () {
      if (this.scTimer) return;
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY;
        clearTimeout(this.scTimer);
        this.scTimer = 0;
      }, 100);
    },
    toTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style>
#app {
  background: #f9f9fc url("~@/assets/adler_bg.png") no-repeat fixed bottom left;
  background-size: auto 44%;
  @apply min-h-screen;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
