<template>
  <div class="divider">
    {{ title }}
  </div>
</template>

<script>
export default {
  name: "SidebarNavigationDivider",
  props: {
    title: String,
  },
};
</script>

<style scoped>
.divider {
  @apply px-5 py-3 bg-gray-50 border-t border-b border-gray-200 font-bold text-sm text-gray-600;
}
</style>
