import { createApp } from "vue";
import App from "./App.vue";
import "./assets/tailwind.css";
import router from "./router";
import VueAxios from "vue-axios";
import api from "@/data/api";
import VueLazyLoad from "vue3-lazyload";
import timeago from "vue-timeago3";
import * as deLocale from "date-fns/locale/de/index.js";
import Axios from "axios";
import VueEasyLightbox from "vue-easy-lightbox";
import { createMetaManager } from "vue-meta";
import { store } from "./store/index";
import "./registerServiceWorker";

const timeagoOptions = {
  locale: deLocale,
};

const axios = Axios;

axios.defaults.baseURL = api.url;
axios.defaults.headers.common["Authorization"] = "Bearer " + api.token;

createApp(App)
  .use(router)
  .use(VueAxios, axios)
  .use(VueLazyLoad)
  .use(VueEasyLightbox)
  .use(timeago, timeagoOptions)
  .use(createMetaManager())
  .use(store)
  .mount("#app");
