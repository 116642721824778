<template>
  <p class="copyright">
    <strong>&copy;2012 - {{ getYear }}</strong
    ><br />
    BSV-Harsewinkel von 1845 e.V.
  </p>
</template>

<script>
export default {
  name: "SidebarCopyright",
  computed: {
    getYear() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style scoped>
.copyright {
  @apply text-center block text-sm lg:text-xs text-gray-600;
}
</style>
