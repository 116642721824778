import { createWebHistory, createRouter } from "vue-router";
import routes from "@/data/routes";

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    window.scrollTo(0, 0);
  },
});

export default router;
