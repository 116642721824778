<template>
  <div class="back-button">
    <!-- Icon -->
    <span class="material-icons-outlined"> keyboard_arrow_left </span>
    <!-- Titel -->
    <span>Zurück</span>
  </div>
</template>

<script>
export default {
  name: "SidebarNavigationBackButton",
};
</script>

<style scoped>
.back-button {
  @apply flex font-bold text-gray-600 items-center space-x-3 px-5 py-3 border-b border-gray-200 hover:bg-gray-100 bg-gray-50 transition-colors ease-linear duration-150 cursor-pointer;
}
</style>
