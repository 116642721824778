<template>
  <router-link class="link" :to="link.to">
    {{ link.title }}
  </router-link>
</template>

<script>
export default {
  name: "HeaderNavigationLink",
  props: {
    link: Object,
  },
};
</script>

<style scoped>
.link {
  @apply text-white text-sm px-3 py-2 hover:bg-black/20 rounded-xl transition-colors duration-150 ease-linear;
}

.router-link-exact-active {
  @apply bg-black/30;
}
</style>
