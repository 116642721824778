<template>
  <div v-bind:class="{ 'page-container': active }">
    <slot />
  </div>
</template>

<script>
export default {
  name: "PageContainer",
  props: {
    active: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped>
.page-container {
  @apply lg:px-10 max-w-6xl mx-auto h-full;
}
</style>
