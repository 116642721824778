export default [
  {
    path: "/",
    name: "News",
    component: () => import(/* webpackChunkName: "News" */ "@/pages/NewsPage"),
    meta: { showSidebar: true },
  },
  {
    path: "/neuigkeiten/:label",
    name: "NewsByLabel",
    component: () =>
      import(/* webpackChunkName: "NewsByLabel" */ "@/pages/NewsByLabel"),
    meta: { showSidebar: true },
  },
  {
    path: "/impressum",
    name: "Contact",
    component: () =>
      import(/* webpackChunkName: "Contact" */ "@/pages/ContactPage"),
    meta: { showSidebar: true },
  },
  {
    path: "/datenschutz",
    name: "Privacy",
    component: () =>
      import(/* webpackChunkName: "Privacy" */ "@/pages/PrivacyPage"),
    meta: { showSidebar: true },
  },
  {
    path: "/gaestebuch",
    name: "Guestbook",
    component: () =>
      import(/* webpackChunkName: "Guestbook" */ "@/pages/GuestbookPage"),
    meta: { showSidebar: true },
  },
  {
    path: "/downloads",
    name: "Downloads",
    component: () =>
      import(/* webpackChunkName: "Downloads" */ "@/pages/DownloadsPage"),
    meta: { showSidebar: true },
  },
  {
    path: "/terminkalender/:label?",
    name: "Calendar",
    component: () =>
      import(/* webpackChunkName: "Calendar" */ "@/pages/CalendarPage"),
    meta: { showSidebar: true },
  },
  {
    path: "/externe-links",
    name: "ExternalLinks",
    component: () =>
      import(/* webpackChunkName: "ExternLinks" */ "@/pages/ExternLinksPage"),
    meta: { showSidebar: true },
  },
  {
    path: "/organisation",
    name: "Organisation",
    component: () =>
      import(/* webpackChunkName: "Organisation" */ "@/pages/OrganisationPage"),
    meta: { showSidebar: true },
  },
  {
    path: "/unser-verein",
    name: "AboutUs",
    component: () =>
      import(/* webpackChunkName: "AboutUs" */ "@/pages/AboutUsPage"),
    meta: { showSidebar: true },
  },
  {
    path: "/bsv-satzung",
    name: "Statute",
    component: () =>
      import(/* webpackChunkName: "Statute" */ "@/pages/StatutePage"),
    meta: { showSidebar: true },
  },
  {
    path: "/fotogalerie",
    name: "PhotoGallery",
    component: () =>
      import(/* webpackChunkName: "PhotoGallery" */ "@/pages/PhotoGalleryPage"),
    meta: { showSidebar: true },
  },
  {
    path: "/fotogalerie/:album",
    name: "PhotoGalleryByAlbum",
    component: () =>
      import(
        /* webpackChunkName: "PhotoGalleryAlbum" */ "@/pages/PhotoGalleryAlbumPage"
      ),
    meta: { showSidebar: true },
  },
  {
    path: "/geschichte-des-vereins/:year?",
    name: "History",
    component: () =>
      import(/* webpackChunkName: "History" */ "@/pages/HistoryPage"),
    meta: { showSidebar: true },
  },
  {
    path: "/galerie/kommandeure",
    name: "Commanders",
    component: () =>
      import(/* webpackChunkName: "Commanders" */ "@/pages/CommandersPage"),
    meta: { showSidebar: true },
  },
  {
    path: "/galerie/praesidenten",
    name: "Presidents",
    component: () =>
      import(/* webpackChunkName: "Presidents" */ "@/pages/PresidentsPage"),
    meta: { showSidebar: true },
  },
  {
    path: "/vereinsfuehrung/geschaeftsfuehrender-vorstand",
    name: "ExecutiveBoard",
    component: () =>
      import(
        /* webpackChunkName: "ExecutiveBoard" */ "@/pages/ExecutiveBoardPage"
      ),
    meta: { showSidebar: true },
  },
  {
    path: "/vereinsfuehrung/erweiterter-vorstand",
    name: "ExtendedBoard",
    component: () =>
      import(
        /* webpackChunkName: "ExtendedBoard" */ "@/pages/ExtendedBoardPage"
      ),
    meta: { showSidebar: true },
  },
  {
    path: "/vereinsfuehrung/kompanien-abteilungen",
    name: "CompaniesAndDetachments",
    component: () =>
      import(
        /* webpackChunkName: "CompaniesAndDetachments" */ "@/pages/CompaniesAndDetachmentsPage"
      ),
    meta: { showSidebar: true },
  },
  {
    path: "/galerie/schuetzenthron",
    name: "Schuetzenthron",
    component: () =>
      import(
        /* webpackChunkName: "Schuetzenthron" */ "@/pages/SchuetzenthronPage"
      ),
    meta: { showSidebar: true },
  },
  {
    path: "/galerie/jugendthron",
    name: "Jugendthron",
    component: () =>
      import(/* webpackChunkName: "Jugendthron" */ "@/pages/JugendthronPage"),
    meta: { showSidebar: true },
  },
  {
    path: "/galerie/kinderthron",
    name: "Kinderthron",
    component: () =>
      import(/* webpackChunkName: "Kinderthron" */ "@/pages/KinderthronPage"),
    meta: { showSidebar: true },
  },
  {
    path: "/galerie/bsv-lebenshilfe-thron",
    name: "LebenshilfeThron",
    component: () =>
      import(
        /* webpackChunkName: "BSVLebenshilfeThron" */ "@/pages/BSVLebenshilfeThronPage"
      ),
    meta: { showSidebar: true },
  },
  {
    path: "/galerie/ehrenmitglieder",
    name: "Ehrenmitglieder",
    component: () =>
      import(
        /* webpackChunkName: "Ehrenmitglieder" */ "@/pages/EhrenmitgliederPage"
      ),
    meta: { showSidebar: true },
  },
  {
    path: "/galerie/heimatordentraeger",
    name: "Heimatordentraeger",
    component: () =>
      import(
        /* webpackChunkName: "Heimatordentraeger" */ "@/pages/HeimatordentraegerPage"
      ),
    meta: { showSidebar: true },
  },
  {
    path: "/galerie/bsv-grosser-verdienstorden",
    name: "GrosserVerdienstorden",
    component: () =>
      import(
        /* webpackChunkName: "GrosserVerdienstorden" */ "@/pages/GrosserVerdienstordenPage"
      ),
    meta: { showSidebar: true },
  },
  {
    path: "/galerie/bsv-ehrenorden",
    name: "Ehrenorden",
    component: () =>
      import(/* webpackChunkName: "Ehrenorden" */ "@/pages/EhrenordenPage"),
    meta: { showSidebar: true },
  },
  { path: "/:catchAll(.*)", redirect: "/" },
];
