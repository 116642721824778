<template>
  <div class="scroll-top-button group select-none">
    <span class="material-icons icon select-none"> arrow_upward </span>
  </div>
</template>

<script>
export default {
  name: "ScrollToTopButton",
};
</script>

<style scoped>
.scroll-top-button {
  @apply border-4 border-white z-40 h-16 shadow-md hover:border-gray-200 hover:shadow-lg cursor-pointer hover:bg-green-900 transition-all duration-150 ease-linear w-16 rounded-full bg-green-800 text-white fixed right-4 bottom-4 flex items-center justify-center;
}
.icon {
  @apply text-xl lg:text-3xl group-hover:animate-pulse;
}
</style>
