<template>
  <Transition name="slide-fade">
    <div
      class="py-3 shadow-lg bg-green-800 text-white border-b border-green-700"
      v-if="message && canShow"
    >
      <PageContainer
        class="text-center text-xs md:text-sm flex items-center justify-between px-6 space-x-4"
      >
        <a
          :href="message.url"
          class="flex items-center space-x-4"
          @click="close"
        >
          <span class="material-icons"> {{ message.icon }} </span>
          <span>{{ message.text }}</span>
        </a>
        <span
          class="material-icons cursor-pointer hover:opacity-90"
          @click="close"
          title="Schließen"
        >
          close
        </span>
      </PageContainer>
    </div>
  </Transition>
</template>

<script>
import PageContainer from "@/components/Wrapper/PageContainer";

export default {
  name: "HeaderNotification",
  components: { PageContainer },
  data() {
    return {
      message: null,
    };
  },
  computed: {
    canShow() {
      return !(
        this.message && localStorage.getItem("info-" + this.message._id)
      );
    },
  },
  mounted() {
    this.loadNotification();
    setInterval(() => this.loadNotification(), 60000);
  },
  methods: {
    getOptions() {
      const today = new Date().toISOString().split("T")[0];

      return {
        fields: {
          active: 1,
          url: 1,
          icon: 1,
          text: 1,
          _id: 1,
        },
        filter: {
          $and: [
            {
              active: true,
            },
            {
              $or: [
                { show_end: null },
                { show_end: "" },
                { show_end: { $gte: today } },
              ],
            },
          ],
        },
        limit: 1,
        populate: 1,
        sort: { _created: -1 },
      };
    },
    loadNotification() {
      this.axios
        .post("/api/collections/get/notifications", this.getOptions())
        .then((r) => {
          if (r.data.total > 0) {
            this.message = r.data.entries[0];
          }
        });
    },
    close() {
      localStorage.setItem("info-" + this.message._id, "hide");
      this.message = null;
    },
  },
};
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.2s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-100%);
  opacity: 0;
}
</style>
