<template>
  <router-link :to="{ name: 'News' }" class="logo-wrapper group">
    <div class="logo">
      <img :src="wappen" />
    </div>
    <div>
      <div class="page-name">Bürgerschützen- und Heimatverein</div>
      <div class="sub-name">Harsewinkel von 1845 e.V.</div>
    </div>
  </router-link>
</template>

<script>
import Wappen from "../assets/wappen.png";

export default {
  name: "AppLogo",
  computed: {
    wappen() {
      return Wappen;
    },
  },
};
</script>

<style scoped>
.logo-wrapper {
  @apply flex items-center space-x-6 hover:opacity-90 duration-150 transition-all ease-linear;
  transition: text-shadow 0.3s ease-in-out;
}
.page-name,
.sub-name {
  text-shadow: 0 15px 30px rgba(0, 0, 0, 0.31), 0 5px 15px rgba(0, 0, 0, 0.38);
}
.logo {
  width: 60px;
  height: 70px;
  @apply scale-125 transition-all ease-linear duration-150 transform;
}
.page-name {
  @apply font-bold text-xl text-white;
}
.sub-name {
  @apply text-white;
}
</style>
