<template>
  <div class="header-navigation-wrapper">
    <HeaderNavigationLink
      v-for="(link, key) in $data.navigation"
      :key="'header-navigation-' + key"
      :link="link"
    />
  </div>
</template>

<script>
import HeaderNavigationLink from "@/components/Links/HeaderNavigationLink";
export default {
  name: "HeaderNavigation",
  components: { HeaderNavigationLink },
  data() {
    return {
      navigation: [
        {
          title: "Datenschutz",
          to: { name: "Privacy" },
        },
        {
          title: "Kontakt & Impressum",
          to: { name: "Contact" },
        },
      ],
    };
  },
};
</script>

<style scoped>
.header-navigation-wrapper {
  @apply flex items-center space-x-6;
}
</style>
