<template>
  <div class="card-title" v-if="!to">
    {{ title }}
  </div>
  <router-link :to="to" v-else class="card-title">
    {{ title }}
  </router-link>
</template>

<script>
export default {
  name: "CardTitle",
  props: {
    title: String,
    to: {
      default: null,
      type: Object,
    },
  },
};
</script>

<style scoped>
.card-title {
  @apply border-b border-gray-200 text-green-900 bg-gray-50 px-5 py-3 font-bold flex items-center space-x-6 rounded-t-xl;
}
</style>
