<template>
  <div class="sticky top-0 z-40 bg-default">
    <HeaderNotification />
    <header class="app-header">
      <div class="header-inner">
        <PageContainer class="app-container">
          <AppLogo />
          <HeaderNavigation />
        </PageContainer>
      </div>
      <div class="header-inner-mobile select-none">
        <div class="flex-1 flex items-center">
          <span class="material-icons cursor-pointer" @click="toggleSidebar">
            menu
          </span>
        </div>
        <router-link :to="{ name: 'News' }" class="mobile-wappen-wrapper">
          <img class="mobile-wappen" :src="wappen" alt="Wappen" />
        </router-link>
        <div class="flex-1"></div>
      </div>
    </header>
  </div>
</template>

<script>
import PageContainer from "@/components/Wrapper/PageContainer";
import AppLogo from "@/partials/AppLogo";
import HeaderNavigation from "@/partials/HeaderNavigation";
import { Sidebar } from "@/mixins/Sidebar";
import Wappen from "../assets/wappen.png";
import HeaderNotification from "@/modules/HeaderNotification";
export default {
  name: "AppHeader",
  components: { HeaderNotification, HeaderNavigation, AppLogo, PageContainer },
  mixins: [Sidebar],
  data() {
    return {
      wappen: Wappen,
    };
  },
};
</script>

<style scoped>
.bg-default {
  background: #025233;
}
.app-header {
  @apply h-auto shadow-2xl;
  background: #025233;
}
.header-inner {
  @apply border-b-4 py-5 border-amber-400 h-full hidden lg:block;
}

.header-inner-mobile {
  @apply lg:hidden flex items-center space-x-6 px-6 py-6 text-white;
}

.app-container {
  @apply flex justify-between items-center;
}

.mobile-wappen {
  @apply w-16 h-auto absolute left-0 -top-6;
}
.mobile-wappen-wrapper {
  @apply relative w-20;
}
</style>
