export const sidebarNavigation = [
  {
    title: "BSV-Harsewinkel",
    childs: [
      {
        title: "Neuigkeiten",
        icon: "newspaper",
        to: { name: "News" },
        active: ["News", "NewsByLabel"],
      },
      /*{
                title: 'Gästebuch',
                icon: 'menu_book',
                to: { name: 'Guestbook' }
            },*/
      {
        title: "Downloads",
        icon: "downloading",
        to: { name: "Downloads" },
      },
      {
        title: "Terminkalender",
        icon: "event_note",
        to: { name: "Calendar" },
        active: ["Calendar"],
      },
      {
        title: "Externe Links",
        icon: "link",
        to: { name: "ExternalLinks" },
      },
    ],
  },
  {
    title: "Wir & Ihr",
    childs: [
      {
        title: "Organisation",
        icon: "filter_list",
        to: { name: "Organisation" },
      },
      {
        title: "Unser Verein",
        icon: "workspaces",
        to: { name: "AboutUs" },
      },
      {
        title: "BSV-Satzung",
        icon: "description",
        to: { name: "Statute" },
      },
      {
        title: "Datenschutz",
        icon: "privacy_tip",
        to: { name: "Privacy" },
      },
      {
        title: "Kontakt & Impressum",
        icon: "alternate_email",
        to: { name: "Contact" },
      },
    ],
  },
  {
    title: "Gestern & Vorgestern",
    childs: [
      {
        title: "Fotogalerie",
        icon: "photo_camera",
        to: { name: "PhotoGallery" },
        active: ["PhotoGalleryByAlbum"],
      },
      {
        title: "Geschichte des Vereins",
        icon: "timeline",
        to: { name: "History" },
        active: ["History"],
      },
      {
        title: "Vorstandsgalerie",
        divider: true,
      },
      {
        title: "Kommandeure",
        icon: "collections",
        to: { name: "Commanders" },
      },
      {
        title: "Präsidenten",
        icon: "collections",
        to: { name: "Presidents" },
      },
    ],
  },
  {
    title: "Vereinsführung",
    childs: [
      {
        title: "Geschäftsführender Vorstand",
        icon: "groups",
        to: { name: "ExecutiveBoard" },
      },
      {
        title: "Erweiterter Vorstand",
        icon: "groups",
        to: { name: "ExtendedBoard" },
      },
      {
        title: "Kompanie & Abteilungen",
        icon: "groups",
        to: { name: "CompaniesAndDetachments" },
      },
    ],
  },
  {
    title: "Throngesellschaften",
    childs: [
      {
        title: "Schützenthron",
        icon: "collections",
        to: { name: "Schuetzenthron" },
      },
      {
        title: "Jugendthron",
        icon: "collections",
        to: { name: "Jugendthron" },
      },
      {
        title: "Kinderthron",
        icon: "collections",
        to: { name: "Kinderthron" },
      },
      {
        title: "BSV-Lebenshilfe-Thron",
        icon: "collections",
        to: { name: "LebenshilfeThron" },
      },
    ],
  },
  {
    title: "Besondere Ehrungen",
    childs: [
      {
        title: "Ehrenmitglieder",
        icon: "collections",
        to: { name: "Ehrenmitglieder" },
      },
      {
        title: "Heimatordenträger",
        icon: "collections",
        to: { name: "Heimatordentraeger" },
      },
      {
        title: "BSV Großer Verdienstorden",
        icon: "collections",
        to: { name: "GrosserVerdienstorden" },
      },
      {
        title: "BSV-Ehrenorden",
        icon: "collections",
        to: { name: "Ehrenorden" },
      },
    ],
  },
];
