<template>
  <PageContainer>
    <div class="main-wrapper">
      <ModalBackground
        class="hide-on-desktop"
        v-bind:class="{ 'hidden-sidebar-bg': !showSidebar }"
        :show="true"
        @click="toggleSidebar"
      />
      <aside v-bind:class="{ 'hidden lg:block': !showSidebar }" class="sidebar">
        <slot name="sidebar" />
      </aside>
      <main class="main-slot">
        <router-view v-slot="{ Component }">
          <transition name="v">
            <component :is="Component" />
          </transition>
        </router-view>
        <div class="block lg:hidden pt-10">
          <SidebarCopyright />
        </div>
      </main>
    </div>
  </PageContainer>
</template>

<script>
import PageContainer from "@/components/Wrapper/PageContainer";
import ModalBackground from "@/components/Modal/ModalBackground";
import { BodyScroll } from "@/mixins/BodyScroll";
import { Sidebar } from "@/mixins/Sidebar";
import SidebarCopyright from "@/modules/Sidebar/SidebarCopyright";

export default {
  name: "MainContainer",
  components: {
    SidebarCopyright,
    ModalBackground,
    PageContainer,
  },
  mixins: [BodyScroll, Sidebar],
  data() {
    return {
      stickySidebar: null,
    };
  },
  watch: {
    $route() {
      this.$store.commit("hideSidebar");
    },
  },
};
</script>

<style scoped>
.main-wrapper {
  @apply flex flex-row lg:pb-10 py-10 px-6 lg:px-0;
}
.sidebar {
  @apply z-50 md:z-0 fixed md:relative left-0 top-0 bottom-0 w-10/12 sm:w-6/12 md:w-72 md:mr-6 bg-white md:bg-transparent;
}
.sidebar .navigation {
  @apply rounded-none !important;
}
.main-slot {
  @apply flex-1;
}
.hidden-sidebar-bg {
  display: none !important;
}

.v-enter-active {
  transition: opacity 0.254s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.hide-on-desktop {
  @apply md:hidden !important;
}
</style>
