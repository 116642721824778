export const BodyScroll = {
  methods: {
    showScrollbar() {
      document.documentElement.style.overflow = "auto";
    },
    hideScrollbar() {
      document.documentElement.style.overflow = "hidden";
    },
  },
};
