import { createStore } from "vuex";

// Create a new store instance.
export const store = createStore({
  state() {
    return {
      showSidebar: 0,
    };
  },
  mutations: {
    showSidebar(state) {
      state.showSidebar = true;
    },
    hideSidebar(state) {
      state.showSidebar = false;
    },
    toggleSidebar(state) {
      state.showSidebar = !state.showSidebar;
    },
  },
});
