<template>
  <Transition name="fade">
    <div class="background" v-if="show">
      <slot />
    </div>
  </Transition>
</template>

<script>
export default {
  name: "ModalBackground",
  props: {
    show: Boolean,
  },
};
</script>

<style scoped>
.background {
  @apply bg-black bg-opacity-40 fixed z-40 left-0 right-0 bottom-0 top-0 flex items-center justify-center overflow-auto backdrop-blur-md;
}
</style>
