<template>
  <div class="card">
    <CardTitle :to="to" v-if="$props.title" :title="$props.title" />
    <div v-bind:class="{ 'with-padding': padding }">
      <slot />
    </div>
  </div>
</template>

<script>
import CardTitle from "@/components/Title/CardTitle";
export default {
  name: "ContentCard",
  components: { CardTitle },
  props: {
    title: String,
    padding: {
      type: Boolean,
      default: false,
    },
    to: {
      default: null,
      type: Object,
    },
  },
};
</script>

<style scoped>
.card {
  @apply bg-white rounded-xl border-gray-200 border;
}

.card :deep(h2) {
  @apply font-bold;
}

.with-padding {
  @apply p-4;
}
</style>
