export const Sidebar = {
  computed: {
    showSidebar() {
      const show = this.$store.state.showSidebar;

      if (show) {
        this.hideScrollbar();
      } else {
        this.showScrollbar();
      }
      return show;
    },
  },
  methods: {
    toggleSidebar() {
      this.$store.commit("toggleSidebar");
    },
  },
};
