<template>
  <aside class="app-sidebar">
    <!-- Navigation -->
    <SidebarNavigation :data="$data.navigation" />
    <!-- NewsTags -->
    <SidebarNewsTags
      v-if="routeName === 'News' || routeName === 'NewsByLabel'"
    />
    <!-- Kalender Tags -->
    <SidebarCalendarTags v-if="routeName === 'Calendar'" />
    <!-- Terminübersicht -->
    <SidebarCalendar v-if="routeName !== 'Calendar'" />
    <!-- Copyright -->
    <div class="hidden lg:block">
      <Copyright />
    </div>
  </aside>
</template>

<script>
import SidebarNavigation from "@/modules/Sidebar/SidebarNavigation";
import Copyright from "@/modules/Sidebar/SidebarCopyright";
import { sidebarNavigation } from "@/data/sidebarNavigation";
import { defineAsyncComponent } from "vue";

export default {
  name: "AppSidebar",
  components: {
    SidebarCalendarTags: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "SidebarCalendarTags" */ "@/modules/Sidebar/SidebarCalendarTags"
      )
    ),
    SidebarNewsTags: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "SidebarNewsTags" */ "@/modules/Sidebar/SidebarNewsTags"
      )
    ),
    SidebarCalendar: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "SidebarCalendar" */ "@/modules/Sidebar/SidebarCalendar"
      )
    ),
    Copyright,
    SidebarNavigation,
  },
  computed: {
    routeName() {
      return this.$route.name;
    },
  },
  data() {
    return {
      navigation: sidebarNavigation,
    };
  },
};
</script>

<style scoped>
.app-sidebar {
  @apply space-y-6;
  z-index: 60;
}

.app-sidebar .card {
  @apply rounded-none md:rounded-xl !important;
}
</style>
